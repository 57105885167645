/* -----------------------------------------
   SUCHE & SUCHSEITE
----------------------------------------- */

#search {
  width: 480px;
  margin: auto;

  #searchbar{

  }
  form{
    display: flex;
    align-items: flex-end;

    input {
      border-radius: 0;
      background: transparent;
      border: none;
      color: $secondary;
      font-size: 18*$px-base;
      border-bottom: solid 4px $quaternary;
      height: 45px;
    }

    input::placeholder {
      color: $tertiary;
      font-weight: bold;
    }
  }
  .wdm-dropdown-menu{
    *{
      color: #000000;
    }
  }
}

#searchbar{
  #searchtext{
    width: 85%;
  }
  #searchsubmit{
    background-image: url('images/icons/header/suche.svg') !important;
    background-repeat: no-repeat;
    width: 55px;
    height: 55px;
    border-bottom: none;
    margin-left: 5px;
  }
}

select.multiselect-solr{
  display: none;
}


  .solrsortbar{
    background-color: #ffffff;

    *{

    }

    #searchformbig{
      margin: 0px;
      width: 100%;
      margin-bottom: 20px;
      border: none;
      display: flex;

      input{
        padding: 10px;
      }

      #searchtext{
        width: 100%;
        border: none;
        margin-left: 8px;
      }
      input[type="submit"]{
        border: none;
        margin-left: 20px;
        @include media-breakpoint-up(lg) {
          width: 356px;
        }
      }
    }

    .result-info{
      text-align: center;
      padding: 10px;
      color: $secondary;
      margin: 40px 0px;
      font-weight: bold;
      font-size: 24*$px-base;

      u{
        text-underline-offset: 8px;
      }
    }

    h3{
      font-size: 16*$px-base;
      font-weight: bold;
      color: $tertiary;
    }

    .btn{
      border-radius: 0;
      text-transform: initial;
    }

    .dynamic-facets{

      .facet{
        padding: 10px;

        .form-control{
          border-radius: 0;
          border: none;
          border: solid 3px $secondary;
          background-color: #ffffff;
          background-clip: border-box;
          height: 40px;
          margin-right: 5px;
        }
      }

      input{
        width: inherit !important;
      }

      .btn-group{
        button{
          border-radius: 0;
          border: solid 3px $secondary;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: #ffffff;

          .multiselect-selected-text{
            font-weight: normal;
            text-transform: initial;
          }
        }
        ul{
          padding: 10px;
          border-radius: 0;
          border: solid 3px $secondary;
        }
      }
    }
  }


  .paginationbar{
    display: flex;
    justify-content: center;

    .pagination{
      border-radius: 0;
      .page-item{
        .page-link{
          border-radius: 0;
          border: none;
          text-decoration: none;
          color: $tertiary;
          font-weight: bold;
          padding: 3px !important;
          margin: 5px !important;
          border-bottom: solid 2px transparent;
          &:hover{
            color: $secondary;
          }
          &.arrow{
            color: $secondary !important;
          }
        }
        &.active{
          .page-link {
            color: $secondary;
            font-weight: bold;
            background-color: transparent;
            border-bottom: solid 2px $secondary;
          }
        }
      }
    }
  }

.solrsearch{
  .nothumbnail{
    width: 100%;
    height: 236px;
    background-color: $secondary;
  }
  .tnbutton{
    .button:hover{
      background-color: $secondary;
      color: $primary;
    }
  }
}

.searchonlymobile{
  display:none;
  @include media-breakpoint-down(xs) {
    display: block;
    padding: 0px 25px !important;
  }
  #searchtext{
    width: 85%;
  }
  #searchsubmit{
    background-image: url('images/icons/header/suche.svg');
    background-repeat: no-repeat;
    width: 55px;
    height: 53px;
    border-bottom: none;
    margin-left: 5px;
  }
  form{
    display: flex;
    align-items: flex-end;
    margin-bottom: 25px;

    input {
      border-radius: 0;
      background: transparent;
      border: none;
      color: $secondary;
      font-size: 18*$px-base;
      border-bottom: solid 4px $quaternary;
      height: 45px;
    }

    input::placeholder {
      color: $tertiary;
      font-weight: bold;
    }
  }
}

.modal{
  .modal-content{
    padding: 15px;
  }
  #searchtext{
    width: 85%;
  }
  #searchsubmit{
    background-image: url('images/icons/header/suche.svg');
    width: 54px;
    height: 53px;
    background-repeat: no-repeat;
    border-bottom: none;
    margin-left: 5px;
  }
  form{
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;

    input {
      border-radius: 0;
      background: transparent;
      border: none;
      color: $secondary;
      font-size: 18*$px-base;
      border-bottom: solid 4px $quaternary;
      height: 45px;
    }

    input::placeholder {
      color: $tertiary;
      font-weight: bold;
    }
  }
}