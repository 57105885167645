.slick, .slickmymobile{
  padding-left: 0px;
  margin-bottom: 60px !important;
  li{
    .row{
      margin: 0;
      .backgroundimagediv{
        @include media-breakpoint-down(xs) {
          background-image: var(--bg-image-small) !important;
          min-height: 320px;
        }
        @include media-breakpoint-up(md) {
          background-image: var(--bg-image) !important;
        }

      }
      .col-12{
        background-size: cover;
        min-height: 470px;
        padding: 0px;
        background-position: center;

        .row{
          height: 100%;
          justify-content: flex-end;
        }
        .contentbox{
          display: flex;
          flex-direction: column;
          justify-content: flex-end;

          &>div{
            background-color: #ffffff;
            border-radius: 0px;
            padding: 25px;
            max-height: 450px;
            font-weight: bold;
            margin: 20px;
            color: $secondary;
            @include media-breakpoint-down(md) {
              font-size: 18*$px-base;
              margin: 100px 20px;
            }
          }
          h1, h2, h3, h4{
            font-weight: bold;
            font-size: 28*$px-base;
            margin-bottom: 15px;
            color: $secondary;
          }

          .button{
            background-color: $primary;
            color: $secondary;
            &:hover{
              background-color: $secondary;
              color: $primary
            }
          }
        }
      }
      &.slickgallery{
        .contentbox{
          &>div {
            background-color: transparent;
          }
        }
        .galleryslide{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          text-align: center;
          min-height: 600px !important;
          .textblock{
            max-width: 775px;
            text-align: center;
            color: #ffffff;
            @include media-breakpoint-down(xs) {
              p{
                font-size: 14*$px-base;
              }
            }
          }
          .isc-source-text {
            position: absolute;
            font-size: 0.9em;
            background-color: rgba(0, 0, 0, 0.7);
            color: rgb(255, 255, 255);
            opacity: 0.8;
            padding: 5px;
            text-shadow: none;
            right: 5px;
            bottom: 5px;
            z-index: 9999;
          }
        }
      }
    }
  }
  .slick-dots{
    bottom: -45px;
    li button:before, li.slick-active button:before{
      font-size: 16px;
      color: $secondary;
      @include media-breakpoint-down(xs) {
        color: $tertiary;
        opacity: 1;
      }
    }
    li.slick-active button:before {
      @include media-breakpoint-down(xs) {
        color: $secondary !important;
      }
    }
  }
}

.home-page{
  .startseite_kopfslider{
    .slick-dots {
      bottom: 20px;
      li button:before, li.slick-active button:before{
        font-size: 16px;
        color: $secondary;
      }
    }
  }
}

.slick3er{
  padding: 0px 50px;
  @include media-breakpoint-down(xs) {
    margin-bottom: 50px;
    padding: 0px;
    .slick-track{
      margin-bottom: 30px;
    }
  }
  .row{
    margin: 0px 10px;
    display: block;
  }
  .slick-slide{
    background-color: transparent !important;
    .blocklink, .blocklink:hover *{
      text-decoration: none !important;
    }
  }
  .slick-arrow{
    height: 45px;
    width: 45px;
    background-repeat: no-repeat;
    border-radius: 45px;
    background-size: 25px 25px;
    background-position: center;
    &.slick-next, &.slick-next:hover{
      background-image: url('images/icons/pfeil-slider-right.svg');
      height: 45px;
      width: 45px;
      background-repeat: no-repeat;
      border-radius: 45px;
      background-size: 25px 25px;
      background-position: center;
    }
    &.slick-next:hover, &.slick-prev:hover{

    }
    &.slick-prev, &.slick-prev:hover{
      background-image: url('images/icons/pfeil-slider-links.svg');
      height: 45px;
      width: 45px;
      background-repeat: no-repeat;
      border-radius: 45px;
      background-size: 25px 25px;
      background-position: center;
    }
    &.slick-prev,&.slick-prev:hover{
      background-position: 7px;
    }
    &::before{
      content: '';
    }
    @include media-breakpoint-down(xs) {
      bottom: -40px;
      top: inherit;

      &.slick-prev{
        left: 95px;
      }

      &.slick-next{
        right: 95px;
      }
    }
  }
}