.home-page{
  .header{
    //min-height: 665px;
  }
}

.page-template-regionews .header{
  //background-image: url("images/header/nahe.jpg");
}

.header{
  background-image: url("images/header.jpg");
  background-position: center;
  min-height: 285px;
  z-index: 300;

  .navbar{
    div{
      text-align: center;
      a, button{
        color: $secondary;
        font-size: 12*$px-base;
      }
    }
  }

  .headline {
    text-align: center;
    padding-top: 80px;

    *{
      color: $secondary;
    }
    h1{
      text-align: center;
      font-size: 30*$px-base;
      line-height: 2rem;

      span{
        font-weight: bold;
        font-size: 21*$px-base;
      }
    }
  }

  .headlineheader{
    min-height: 100px;
    *{
      color: $secondary;
    }
    h1{
      font-weight: normal;
    }
    .date{
      font-weight: bold;
    }
    @include media-breakpoint-down(sm){
      h1{
        font-size: 21*$px-base;
      }
    }
  }
}
.wordlogo{
  margin-bottom: 25px;
  margin-left: 12px;
  a{
    font-weight: 800;
    color: $secondary !important;
    font-size: 50*$px-base;
    letter-spacing: 0.57px;
    line-height: 52*$px-base;
    &:hover{
      text-decoration: none;
    }
    @include media-breakpoint-down(lg){
      font-size: 31*$px-base;
      line-height: 33*$px-base;
    }
  }
}

.slimheader{
  transition: 300ms height;
  z-index: 400;
  height: 120px;
  padding: 0px 35px;
  max-width: 1240px;
  margin: auto;

  &.ondesktop2{
    @include media-breakpoint-up(lg){
      display: flex;
      justify-content: center;
      align-content: stretch;
      align-items: center;
    }
    @include media-breakpoint-down(md){
      display: none;
    }

    >div{

    }

    #logo{
      text-align: center;
      margin-right: 10%;
      svg{
        width: 150px;
        fill: #4D5256;
      }
    }


  }
}

.icon-menu{
  display: flex;
  justify-content: center;

  .submenu-icon{
    text-align: center;
    margin: 0px 15px;
    img{
      width: 50px;
    }
    a{
      color: $secondary;
      font-weight: bold;
      &:hover{
        text-decoration: none !important;
      }
    }
  }
}

.shiftnav-inner .icon-menu{
  display: block;

  .submenu-icon{
    margin: 10px 0px;
  }
}

nav.shiftnav-nav{
  border-bottom: solid 1px;
}

html body .slimheaderpostpages{
  .logo {
    a, button {
      color: $secondary;
    }
  }
  .slimheadernav{
    z-index: 300;
    a{
      color: $secondary;
    }
  }

  .navbar-toggler{
    background-image: url("images/icons/header/menu.png");
    span{
      color: $secondary;
    }
  }
}

.slimheader, .slimheaderpostpages{
  overflow:hidden;
  width: 100%;

  &.onmobile2{
    @include media-breakpoint-down(md){
      display: flex !important;
    }
    @include media-breakpoint-up(lg){
      display: none !important;
    }
    .logo {
      width: 150px;
      svg  {
        fill: #4D5256;
      }
      @include media-breakpoint-down(xs) {
        display: flex !important;
        img{
          width: 120px;
        }
        a{
          font-size: 21*$px-base;
        }
      }
    }
  }
  @include media-breakpoint-down(xs) {
    &.ondesktop {
      display: none !important;
    }
  }

  .logo {
    @include media-breakpoint-up(lg) {
      margin-left: 25px;
    }
    @include media-breakpoint-down(xs) {
      display: none !important;
    }
    a, button {
      color: $primary;
    }
    h1 {
      text-align: center;
      font-size: 18*$px-base;
      font-weight: bold;
      a{
        font-weight: bold;
      }
      span {
        font-weight: normal;
        font-size: 12*$px-base;
      }
    }
  }
  .logorlp{
    img{
      width: 280px;
      margin: 5px;
    }
  }
  &.logobox{
    justify-content: space-between;
    @include media-breakpoint-down(xs) {
      justify-content: center;
    }
  }

  .slimheadernav{
    div{
      text-align: center;
    }
    a{
      font-weight: normal;
      text-transform: none;
      font-size: 12*$px-base;
      line-height: 1rem;
    }
    button span{
      color: $primary;
    }
  }

  &.open{
    height: 92px;
  }

  .navbar-toggler{
    background-image: url("images/icons/header/menu_blau.png");
    width: 40px;
    border:none;
    border-radius: 0;
    background-repeat: no-repeat;
    color: $secondary;
    font-size: 12*$px-base;
    font-weight: bold;
    position: relative;
    padding: 32px 0px 0px 0px;
    span{
      display: block;
      padding-top: 10px;
      width: 40px;
      position: relative;
    }
  }

  &.menuopen{
    height: 92px;
    .navbar-toggler{
      background-image: url("images/icons/header/schliessen.png");
    }
  }
}

.breadcrumbbar{
  color: #4d4d4d;
  font-weight: normal;
  .seperator{
    color: #000000;
    font-weight: normal;
    display: inline-block;
    margin: 0px 5px;
  }
  a{
    color: $secondary;
    font-weight: normal;
  }
  span{
    color: $secondary;
    font-weight: bold;
  }
}

.poweredbyrptbar{
  background-color: #e4ded0;
  padding: 30px;

  .inhalt{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .text{
      padding-right: 30px;
    }
  }
}

@media (min-width: 768px) {
  .slimheader, .slimheaderpostpages{
    .logo {
      h1 {
        font-size: 27*$px-base;
        line-height: 1.2rem;
        padding-top: 15px;
        span {
          font-weight: normal;
          font-size: 16*$px-base;
        }
      }
    }
  }
  .header{
    min-height: 400px;
    .headlineheader{
      min-height: 220px;
    }
  }
}

.gelberbalken{
  background-color: $primary;
  padding-top: 20px;
  .breadcrumbbar{
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}
.gelberbalken-startseite{
  background: linear-gradient(0deg, $primary 78%, rgba(255,255,255,1) 40px);
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.yellowbackbox{
  position: absolute;
  height: 295px;
  width: 100%;
  background-color: $primary;
  z-index: 1;
  @media screen and (min-width:100px) and (max-width:321px) {
    height: 178px;
  }
  @media screen and (min-width:322px) and (max-width:376px) {
    height: 198px;
  }
  @media screen and (min-width:377px) and (max-width:426px) {
    height: 214px;
  }
  @media screen and (min-width:427px) and (max-width:769px) {
    height: 251px;
  }
  @media screen and (min-width:770px){
    height: 295px;
  }
}

body.home{
  .yellowbackbox {
    position: absolute;
    height: 400px;
    @include media-breakpoint-down(xs) {
     height: 760px;
    }
  }
  .slimheader.onmobile2.logoblockmobile{
    @include media-breakpoint-down(md) {
      display: block !important;
    }
    background-color: $primary;
    height: 80px;
    position: relative;
    .whiteback{
      z-index: 5;
      position: absolute;
      height: 27px;
      width: 100%;
      left: 0px;
      right: 0px;
      background-color: #ffffff;
    }
    .wordlogo{
      position: relative;
      z-index: 10;
    }
  }
}

html #shiftnav-toggle-main~#wpadminbar{
  @include media-breakpoint-down(xs){
    display: none;
  }
}

.slimheader.onmobile2.logoblockmobile{
  display: none !important;
}

.page-template-page-schmalergelberbalken .yellowbackbox{
  height: 80px;
}

.uebermenunavbox{
  padding-top: 55px;
}