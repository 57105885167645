.page-template-weiterbildung{
  .type-tribe_events {
    .gueltigbis{
      text-decoration: underline;
    }
    a:hover{
      text-decoration: none;
      .article{
        background-color: $quaternary;
        h3{
          color: $tertiary
        }
      }
    }
    .article{
      background-color: $quaternary_light;
      padding: 20px;
      min-height: 400px;
    }


    h3{
      font-size: 22px;
    }
    .read-more a{
      font-weight: bold;
    }
    dt{
      text-align: left;
    }
  }

  .dynamic-facets .author{
    display: none;
  }

  .solrsortbar #searchformbig #searchtext{
    border-radius: 0;
    background: transparent;
    border: none;
    color: #4B5055;
    font-size: 1.125rem;
    border-bottom: solid 4px #D7D7DC;
    height: 45px;
  }
}

#tribe-events-content.elearning{
  .tribe-events-schedule{
    display: none;
  }
}
.weiterbildungteaser{
  .article{
    padding: 15px;
    background-color: $quaternary40;

    .date{
      color: $tertiary;
      margin-bottom: 14px;
      font-size: 14*$px-base;
      font-weight: 700;
    }
    .headline a{
      text-decoration: none;
      h3{
        color: $secondary;
        font-size: 21*$px-base;
      }
    }
    .read-more{
      display: flex;
      justify-content: end;
      float: none;
      a{
        display: block;
        margin-left: 15px;
      }
      .greybutton{
        display: inline-block;
        border-radius: 0px;
        padding: 4px 10px 4px 10px;
        align-self: start;
        font-weight: bold;
        color: #4B5055;
        border: none;
        text-decoration: none;
        background-color: transparent;
      }
    }
  }
}

.fullpage.cat_weiterbildung{
  .tribe-events-event-url-label{
    visibility: hidden;
    position: relative;
    &:after{
      visibility: visible;
      position: absolute;
      top: 0;
      left: 0;
      content: 'Anmeldung:';
    }
  }
  .tribe-events-meta-group-other{
    display: none;
  }
  .tribe-events-meta-group-venue{
    display: none;
  }
  .tribe-events-meta-group-details{
    dl{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px;
      grid-auto-flow: dense;
    }
  }
}

.page-template-weiterbildung{
  #searchsubmit{
    background-image: url('images/icons/header/suche.svg');
    background-repeat: no-repeat;
    background-color: transparent !important;
    width: 55px !important;
    height: 55px;
    border-bottom: none;
    margin-left: 5px;
  }
}