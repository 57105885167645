.type-stellenangebote{
  .article{
    background-color: #ffffff;
    margin-bottom: 20px;
    padding: 20px;

    border: solid 3px $secondary;

    .headline{
      a{
        text-decoration: none;
      }
      *{
        color: #000000;
      }
    }
    .dl-horizontal{
      dt{
        width: 200px;
      }
      dd{
        width: 230px;
        margin-left: 220px !important;
        @include media-breakpoint-down(sm) {
          float: right;
        }
      }
    }
    @include media-breakpoint-down(sm) {
      .tnbutton{
        width: 100%;
        justify-content: center;
      }
    }
  }
}

.singlepost.stellenangebote{
  .headbox{
    background-color: $quaternary;
    padding: 20px;
    h3{
      color: $secondary;
    }
  }
}