/* -----------------------------------------
   EVENT PLUGIN STYLE
----------------------------------------- */

.ecs-event .card .card-body p .text-muted {
  color: $primary !important;
}

html body.single-tribe_events #tribe-events-pg-template, .tribe-events-pg-template{
  max-width: 100%;
  padding: 0px;
}

html body.single-tribe_events .tribe-events-pg-template>#tribe-events{
  padding: 0px;
}

@include media-breakpoint-up(md) {
  .ecs-events {
    .ecs-event:nth-child(2n) {
      border-left: solid 2px #ffffff;
      border-right: solid 2px #ffffff;
    }
  }
}
@include media-breakpoint-down(xs) {
  .ecs-events {
    .ecs-event:nth-child(2n) {
      border-top: solid 2px #ffffff;
      border-bottom: solid 2px #ffffff;
    }
  }
}

.ecs-events{
  overflow: visible;
}
.ecs-event {
  .ecs-wrap{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;

    @include media-breakpoint-down(xs) {
      .card-title, .card-text, .tnbutton{
        margin: 0px !important;
      }
    }

    *{
      text-align: center !important;
    }
    .text-muted{
      color: $tertiary !important;
      span{
        font-weight: bold !important;
      }
      * {
        font-weight: normal;
        color: $tertiary !important;
        text-align: center !important;
      }
    }
    .card-title{
      a{
        font-weight: bold;
        font-size: 26*$px-base;
        text-align: center !important;
        text-decoration: none;
      }
      a:hover{
        text-decoration: none;
        color: $secondary;
      }
    }
    .icon{
      width: 62px;
      height: 62px;
      margin:auto;
      path, rect{
        fill: $secondary;
      }
    }
    .card-footer{
      .btn{
        background-color: #d5dde0;
        color: $secondary;
        border-color: $secondary;
      }
    }
    .card-body{
      min-height: 160px;
    }
    .card-img-top{
      height: auto;
    }
    .tnbutton{
      margin-top: 35px;
      justify-content: center;
      @include media-breakpoint-down(xs) {
        margin-top: 15px !important;
      }
    }
  }
}


html body #tribe-events{
  .tribe-events-ical, .tribe-events-gcal{
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.175rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 20px;
    background-color: #ffffff;
    border-color: #233240;
    color: #233240;
    font-size: 12*$px-base;
  }

  .tribe-events-event-image {
    text-align: left;
    img {

      max-width: 365px;
    }
  }
  .tribe-events-event-meta{
    background-color: $quaternary;
  }
  .tribe-events-title-bar{
    display: none;
  }
}

#tribe-events-header{
  .tribe-events-sub-nav{
    display: none;
  }
}

.tribe-events-tooltip{
  .tribe-events-event-thumb{
    display: none;
  }
}

.tribeteilnehmerliste{
  width: 100%;
}

.tribe-tickets-order_status-row, .tribe-tickets-attendees-list-optout{
  display: none !important;
}


html body #tribe-events .tribe-events-list{
  .tribe-events-event-meta{
    background-color: #ffffff !important;
  }
}

.tribe-events-related-events-title{
  font-size: 24*$px-base;
  color: $primary;
  text-align: center;
  margin: 40px 0px !important;
  background-image: url("images/icons/ostbayern/calendar-open.svg");
  background-size: 50px;
  background-repeat: no-repeat;
  background-position: top center;
  padding-top:  50px !important;
  border-top: none !important;
}

.tribe-related-events-thumbnail img{
  width: 324px !important;
  height: 172px !important;
}

.tribe-events-visuallyhidden{
  display: none;
}

.tribe-events-single ul.tribe-related-events li{
  background: none !important;
  -webkit-box-shadow: 0px 0px 10px -1px rgba(51,51,51,0.5);
  box-shadow: 0px 0px 10px -1px rgba(51,51,51,0.5);
  border-radius: $border-radius;
}

.tribe-events-tickets header{
  font-weight: bold !important;
}

.tribe-events-sub-nav a{
  color: #ffffff !important;
}

#tribe-events-footer{
  .tribe-events-sub-nav a{
    color: $primary !important;
    background-color: $secondary !important;
    padding: 4px 10px;

  }
}
.tribe-events-single .tribe-events-sub-nav{
  display: none !important;
}

.tribe-mobile-day .tribe-events-event-image{
  display: none;
}

.single-tribe_events .tribe-events-single .tribe-events-event-meta{
  width: 100% !important;
  padding: 25px;
  background-color: $tertiary !important;
  font-size: 18*$px-base !important;

  .tribe-events-single-section-title{
    font-weight: bold;
    color: #000000;
    font-size: 18*$px-base !important;
  }
  dd, dd *{
    color: white !important;
  }
  dt{
  }
}
.tribe-events-meta-group{
  padding-top: 0px !important;
}
.tribe-events-event-meta:before{
  border: none !important;
}

.tribe-related-events{
  .tribe-related-event-info{
    padding: 5px 20px !important;
  }
}

.tribe-events-event-image img{
  width: 100%;
  height: auto;
  margin-bottom: 35px;
}

html body .tribe-events-event-image{
  margin-bottom: 0px;
}

.tribe-events-pg-template h1{
  font-weight: bold;
}

.tribe-events-schedule *{

  font-weight: bold;
}

.ecs-events.ecs-grid.ecs-grid-1 .ecs-event {
  @include media-breakpoint-down(md) {
    width: 100% !important;
  }
  @include media-breakpoint-down(xs) {
    width: 100% !important;
  }
}

.tribe-common{
  z-index: 2;
  .tribe-common-l-container{
    background-color: #ffffff;
  }
}

.tribe-events-c-events-bar--border{
  border: solid 2px $tertiary !important;
}

.tribe-common--breakpoint-medium.tribe-events .tribe-events-c-view-selector--tabs .tribe-events-c-view-selector__list-item--active .tribe-events-c-view-selector__list-item-link:after{
  background-color: $primary !important;
  height: 3px !important;
  bottom: 0px !important;
}

.tribe-common .tribe-common-c-btn, .tribe-common a.tribe-common-c-btn{
  background-color: $primary !important;
  color: $secondary !important;
  border-radius: 0px !important;
  border: none !important;
}

.tribe-common .tribe-common-c-btn-border, .tribe-common a.tribe-common-c-btn-border{
  background-color: $primary !important;
  color: $secondary !important;
  border-radius: 0px !important;
  border: none !important;
}

.tribe-common .tribe-common-c-btn-border-small, .tribe-common a.tribe-common-c-btn-border-small {
  border-radius: 0px !important;
}

.tribe-events-c-subscribe-dropdown .tribe-common-c-svgicon{
  display: none !important;
}

.tribe_events-template-default .headerbackbutton{
  display: none !important;
}

.single-tribe_events .tribe-events-c-subscribe-dropdown .tribe-events-c-subscribe-dropdown__button, .tribe-events .tribe-events-c-subscribe-dropdown .tribe-events-c-subscribe-dropdown__button{
  padding: 11px 20px;
}

.tribe-events-calendar-list__event-datetime-wrapper *{
  font-weight: bold !important;
  color: $tertiary !important;
}

.tribe-common.tribe-common--breakpoint-medium button.tribe-events-c-top-bar__datepicker-button{
 font-size: 20px !important;
}

.tribe-events .tribe-events-c-events-bar .tribe-common-form-control-text__input::placeholder{
  color: $tertiary !important;
  font-weight: bold !important;
}

.tribe-events-c-top-bar__datepicker-time{
  * {
    font-weight: bold !important;
    font-size: 17*$px-base !important;
    color: $secondary !important;
    text-decoration: none !important;
  }
}

.tribe-common--breakpoint-medium.tribe-common .tribe-common-form-control-text__input{
  font-size: 18*$px-base !important;
  color: $secondary !important;
}

.tribe-events-calendar-list__month-separator-text{
  color: $tertiary !important;
}

.tribe-events-calendar-list__event-date-tag-datetime{
  .tribe-events-calendar-list__event-date-tag-weekday{
    padding: 10px 5px 0px 5px !important;
    background-color: #faf0a2;
  }
  .tribe-events-calendar-list__event-date-tag-daynum{
    padding: 0px 5px 10px 5px !important;
    background-color: #faf0a2;
  }

}

.tribe-events-c-nav__list-item {
  button span, a{
    font-size: 18*$px-base;
    color: $secondary;
  }
  button:disabled span{
    color: var(--tec-color-text-disabled);
  }
}

.tribe-events-meta-group-other{
  display: none;
}

.single-tribe_events .tribe-events-single .tribe-events-event-meta .tribe-events-single-section-title{
  text-transform: none !important;
}

.tribe-events-meta-group .tribe-events-single-section-title{
  text-transform: none !important;
}

.tribe-common article.tribe_events_cat-weiterbildung h3 a, .tribe-common article.tribe_events_cat-weiterbildung h3 a:link{
  word-break: break-word;
}

.ecs-events{
  min-height: 320px;
}