html body .shiftnav:after{
  -webkit-box-shadow: none;
  box-shadow: none;
}

html body .shiftnav-toggle-button{
  background-color: transparent;
  color: $secondary;
  font-size: 32*$px-base;
  @include media-breakpoint-up(lg){
    padding: 0px !important;
  }
  @include media-breakpoint-down(lg){
    font-size: 45*$px-base !important;
  }
  &:hover{
    background-color: transparent !important;
    color: $tertiary !important;
  }
}

body .shiftnav{
  background-color: $primary;

  a{
    color: $secondary;
  }
}

#shiftnav-toggle-main.shiftnav-toggle-edge-right .shiftnav-toggle-burger{
  display: none;
}
.shiftnav-open #shiftnav-toggle-main.shiftnav-toggle-edge-right .shiftnav-toggle-burger{
  display: block;
}

.gelberbalken .shiftnav-toggle{
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.menu-hauptnavigation-container{
  display: flex;
  flex-direction: row;
  align-items: self-end;
  height: 100%;
  justify-content: end;
  padding-bottom: 19px;

  @include media-breakpoint-down(md) {
    display: none;
  }

  ul{
    list-style: none;
    display: flex;
    justify-content: end;
    align-items: baseline;

    a{
      color: $secondary;
      display: block;
      padding: 20px 15px 0px 15px;
      height: 100%;
      font-weight: bold;
      font-size: 21*$px-base;
    }
  }
}

.headerbackbutton{
  margin-left: 35px;
  margin-top: 10px;
  display: inline-block;
  background-color: $secondary;
  border-radius: 0px;
  padding: 4px 10px 4px 10px;
  align-self: start;
  font-weight: bold;
  font-size: 18*$px-base;
  color: $primary !important;
  text-align: center;
  text-decoration: none !important;
  @include media-breakpoint-down(xs) {
    margin-left: 19px;
  }
}

.blogcontentmain .headerbackbutton{
  margin-left: 0px;
}

#menu-footermenue{
  list-style-type: none;
  display: block;
}