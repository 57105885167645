
/* -----------------------------------------
   POST PAGE (single.php)
----------------------------------------- */

.autorbalken{
  padding: 30px;
  background: linear-gradient(180deg, #fff 40%, $quintary 40% 100%);
}

.boxauthor{
  *{
    font-size: 18*$px-base;
  }
  a{
    color: #000000;
    font-size: 18*$px-base;
  }
}

.authorinfobox{
  font-size: 13*$px-base;

  .emailicon{
    display: inline-block;
    width: 35px;
    height: 35px;
    svg path{
      fill: $primary;
    }
  }
}

.authorname{
  color: #000000 !important;

  span, a{
    font-weight: bold;
    color: #000000 !important;
  }
}

html body .singlepost .categories{
  a{
    font-weight: bold !important;
    background-color: $quaternary_light;
    color: #ffffff;
    display: inline-block;
    padding: 5px;
    font-size: 14*$px-base;
    margin: 5px 0px;
  }
}

hr {
  border: none !important;
  border-bottom: solid 1px $primary !important;
}

/* -----------------------------------------
   RELATED POSTS
----------------------------------------- */

.related_post_title{
  display: none;
}

.related_post{
  list-style: none;
  padding-left: 0;
  display: flex;
  align-items: stretch;
}

.related_post li {
  background-color: $primary;
  display: flex;
  flex-direction: column;
  width: 30%;
  margin: 5px;
  margin-bottom: 20px;

  * {
    color: #ffffff;
    text-align: left;
  }

  img {
    width: 100%;
    height: auto;
  }

  small{
    padding: 5px 20px !important;
  }

  .wp_rp_title {
    font-size: 16*$px-base;
    margin: 0;
    padding: 5px 20px !important;
    margin-bottom: 20px;
  }
}



@include media-breakpoint-down(sm){
  .related_post{
    display: block;

    li{
      width: 100%;
    }
  }
}

/* -----------------------------------------
   Copyrigth
----------------------------------------- */

.single-post .thumbnailboxwithquelle{
  max-width: 100%;
  width: inherit;
  margin-bottom: 20px;
}

.single-post img.size-full{
  width: 100%;
  height: auto;
}

.postgridview{
  .thumbnailboxwithquelle{
    padding-top: 69%;
    img{
      position: absolute;
    }
  }
  @include media-breakpoint-down(sm){
    padding-top: 0px !important;
  }
}

.isc_image_list_title{
  font-weight: bold;
}
.isc-source-text {
  background-color: 000000 !important;
  color: rgb(255, 255, 255) !important;
  opacity: 0.8 !important;
}

.slick3er{
  .thumbnailboxwithquelle {
    .isc-source-button{
      right: 30px;
    }
    .isc-source-text {
      left: 15px;
      width: calc(100% - 90px);
    }
  }
}
.thumbnailboxwithquelle {
  position: relative;
  display: inline-block;
  width: 100%;

  background-color: #ffffff;

  p {
    margin: 0 !important;
  }
  .isc-source{
   position: static;
    display: inline;
  }

  .isc-source-button{
    position: absolute;
    right: 0px;
    top: 0px;
    font-weight: bold;
    padding: 10px;
    z-index:50;
    font-size: 18*$px-base;
    a{
      color: #ffffff;
      text-decoration: none;
      font-size: 28px;
    }
  }
  .isc-source-text, .isc-source-text-sf {
    display: none;
  }
  .isc-source-text, .isc-source-text-sf  {
    position: absolute;
    font-size: 0.9em;
    background-color: #000000;
    color: rgb(255, 255, 255);
    opacity: 0.8;
    padding: 0px 0.15em;
    text-shadow: none;
    left: 5px;
    top: 5px;
    z-index: 9999;
    display: none;
    width: calc(100% - 30px);
  }

  img{
    max-width: 100%;
    width: 100%;
    border-radius: $border-radius;
    top: 0;
    z-index: 1;
  }
}

.isc-source{
  position: relative;
  display: inline-block;
  background-color: #ffffff;

  p {
    margin: 0 !important;
  }

  .isc-source-button{
    position: absolute;
    right: 10px;
    top: 0px;
    background-color: #000000;
    border-radius: 0px 0px 3px 3px;
    opacity: 0.6;
    font-weight: bold;
    padding: 10px;
    z-index:200;
    font-size: 18*$px-base;
    a{
      color: #ffffff;
      text-decoration: none !important;
    }
  }
  .isc-source-text, .isc-source-text-sf {
    display: none;
  }
  .isc-source-text, .isc-source-text-sf {
    position: absolute;
    font-size: 0.9em;
    background-color: #000000;
    color: rgb(255, 255, 255);
    opacity: 0.6;
    padding: 0px 0.15em;
    text-shadow: none;
    left: 5px;
    top: 5px;
    z-index: 9999;
    display: none;
    width: 100%;

    a{
      color: #ffffff !important;
    }
  }

  img{
    max-width: 100%;
    width: 100%;
    border-radius: 0px;
    top: 0;
    z-index: 1;
  }
}

.vc_grid-item .isc-source .isc-source-button{
  top: -17px;
}

.slick3er{
  .isc-source{
    position: absolute;
    top: 0px;
    right: 20px;
    width: 100%;
  }
  .isc-source-text-sf{
    top: 10px;
    left: 30px;
    font-size: 0.8em;
    background-color: #000000;
    color: rgb(255, 255, 255);
    opacity: 0.6;
    padding: 0px 0.15em;
    text-shadow: none;
    width: calc(100% - 90px);
    display: none;
    a{
      color: #ffffff !important;
    }
  }
}
.slick{
  .isc-source-text {
    position: absolute;
    top: 0px;
    right: 20px;
    top: 10px;
    font-size: 0.8em;
    background-color: #000000;
    color: rgb(255, 255, 255);
    opacity: 0.6;
    padding: 0px 0.15em;
    text-shadow: none;

    a {
      color: #ffffff !important;
    }
  }
}

//ALLGEMEINE STYLES

.fulldropdownshow{
  width: 100% !important;
}

.printericon{
  padding-top: 15px;
  svg{
    width: 45px;
  }
}

.ccchildpages{
  &.threecol{
    .ccchildpage{
      min-height: 290px;
    }
  }
  &.twocol{
    .ccchildpage{
      min-height: 390px;
    }
  }
  .ccchildpage{
    background-color: #8e1c3f !important;
    border-radius: 0px !important;
    padding: 0px !important;
    display: flex;
    flex-direction: column;


    *{
      color: #ffffff;
    }

    .cc-child-pages-thumb{
      order: -1;
      margin: 0px !important;
      width: 100% !important;
    }

    h3, .ccpages_excerpt, .ccpages_more{
      padding: 0px 15px;
    }

    h3{
      padding-top: 8px;
    }
    .ccpages_more a{
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      user-select: none;
      border: 1px solid transparent;
      padding: 0.175rem 0.75rem;
      font-size: 1rem;
      line-height: 1.5;
      border-color: #ffffff;
      display: inline-block;
      font-weight: 700;
      color: #fff;
      background-color: $primary;
      border-radius: 20px;
      font-style: normal;
    }
  }
}

a.blocklink:hover{
  text-decoration: none !important;
}
a .tnteaser{
  text-decoration: none !important;
}
.tnteaser{
  min-height: 566px;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-size: auto, cover;
  background-position: bottom, center;
  background-repeat: no-repeat;
  margin-bottom: 30px;
  padding: 0px;
  h4{
    color: $primary;
  }
  .isc-source-text{
    top: 5px;
    position: absolute;
    max-width: 94%;

    *{
      color: #ffffff !important;
      font-size: 0.8em;
    }
    span{
      left: 5px;
    }
  }
  .text{
    padding: 20px;
    min-height: 150px;
  }
  *{
    color: $primary;
    text-decoration: none !important;
  }
  .date{
    font-size: 16*$px-base;
    line-height: 1.3rem;
    font-weight: normal;
    padding-bottom: 10px;
    display: inline-block;
  }
  h3{
    font-size: 18*$px-base;
    font-weight: bold;
    text-decoration: none;
    margin-bottom: 5px;
  }
  .buttonbox{
    margin-top: 20px;
    .button{
      text-decoration: none;
      display: inline;
      float: right;
      background-color: $primary;
      border-radius: 0px;
      padding: 4px 10px 4px 10px;
      align-self: start;
      font-weight: bold;
      font-size: 18*$px-base;
      color: $secondary;
      text-align: center;
      text-decoration: none !important;
      &:hover{
        color: $primary;
      }
    }
  }
}

.tnicon{
  &.big svg {
    width: 85px;
    height: 85px;
    margin-bottom: 20px;
  }
  svg {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
    path{
      fill: $primary;
    }
  }
}
.vc_row-has-fill{
  svg path{
    fill: $secondary;
  }
}

.tnbutton{
  display: flex;
  a{
    display: inline;
    background-color: $primary;
    border-radius: 0px;
    padding: 4px 10px 4px 10px;
    align-self: start;
    font-weight: bold;
    font-size: 18*$px-base;
    color: $secondary;
    text-align: center;
    text-decoration: none;

    .iconpfeil{
      height: 12px;
      width: 12px;
      margin-left: 10px;
      display: inline-block;
    }
    &:hover{
      text-decoration: none !important;
    }
  }
  a.farbe01{
    background-color: $primary;
    &:hover{
      background-color: $primaryhover;
    }
  }
  a.farbe02{
    background-color: $secondary;
    &:hover{
      background-color: $tertiary;
    }
  }
  a.farbe03{
    background-color: $tertiary;
    &:hover{
      background-color: $secondary;
    }
  }
  a.weiss{
    background-color: #ffffff;
    color: $tertiary !important;
    .iconpfeil svg *{
      stroke: $tertiary !important;
    }
  }
  &.right{
    justify-content: flex-end;
  }
  &.center{
    justify-content: center;
  }
  &.left{
    justify-content: start;
  }
}

html body .vc_grid.vc_row.vc_grid-gutter-35px .vc_grid-item{
  padding-right: 45px;
  padding-bottom: 45px;
}

.blogbeitraege{
  .vc_pageable-slide-wrapper{
    display: flex;
    flex-wrap: wrap;
  }
  .vc_grid-item-mini.vc_clearfix,
  .vc_gitem-animated-block,
  .vc_gitem-zone,
  .vc_gitem-zone-a .vc_gitem-zone-mini,
  .vc_gitem-zone-a .vc_gitem-row-position-top,
  {
    height: 100%;
  }
}

.postgridview{
  .marginbottomspacer{
    margin-bottom: 60px;
  }
  .card{
    height: 100%;
    margin: 20px 40px 20px 0px;
    background-color: $quaternary40;

    @include media-breakpoint-down(xs) {
      margin: 0px;
    }

    a:hover{
      text-decoration: none !important;
    }
    .card-text,.card-text * {
      color: $secondary;
    }
    .card-title *{
      color: $secondary;
      font-weight: bold;
      font-size: 21*$px-base;
      text-decoration: none;
    }
    .card-body{
      padding: 0px 20px 0px 20px;
      flex: none;
    }
    .text-muted{
      font-size: 16*$px-base;
      color: $tertiary !important;
      font-weight: bold;
    }
    .katbox{
      text-align: right;
      a{
        background-color: $primary;
        border-radius: 0px;
        color: $secondary;
        display: inline-block;
        padding: 5px 10px;
        font-size: 12*$px-base;
        min-height: 37px;
        display: inline-flex;
        align-items: end;
        font-weight: bold;
        text-decoration: none;

        &:hover{
          background-color: #9ca2a8 !important;
        }
      }
    }
    .card-footer{
      background-color: transparent;
      padding: 0px 20px 20px 20px;
    }
  }
}
.postgridschmall{
  h4{
    font-size: 19*$px-base;
  }
  .vc_gitem-row-position-top{
    .vc_gitem-col{
      min-height: 695px !important;
    }
  }
}
html body .tnpostgridbox{
  overflow: visible !important;

  .vc_gitem-row-position-top{
    position: relative !important;
    background-color: $quaternary40;
    margin: 20px;

    .vc_gitem-col{
      min-height: 720px;
      padding: 0px !important;
      >div{
        margin: 0px 20px;
      }
    }

    .wpb_single_image{
      margin: 0px !important;
      .vc_figure{
        display: block;
        text-align: center;

      }
    }
    .wpb_single_image{
      margin-bottom: 10px !important;
    }
    .wpb_single_image img{
      border-radius: $border-radius;
    }

    .vc_grid-filter {
      text-align: right !important;
      display: block !important;
    }
    .vc_gitem-post-data-source-post_categories{
      margin-bottom: 0px;
      span{
        background-color: $quaternary;
        border-radius: 0px 0px 3px 3px;
        color: $tertiary;
        display: inline-block;
        padding: 5px 15px;
        font-size: 16*$px-base;
      }
    }
    .vc_gitem-post-data-source-post_categories > div{
      display: none;
    }
    .vc_gitem-post-data-source-post_date{
      font-size: 16*$px-base;
      color: $tertiary !important;
      margin-bottom: 1rem !important;
    }
    h5{
      font-size: 21*$px-base;
      font-weight: bold;
      color: #000000;
      hyphens: auto;
      -webkit-hyphens: auto;
    }

    .vc_gitem-post-data-source-post_categories > div:first-of-type{
      display: block;
    }
    .vc_grid-filter.vc_grid-filter-color-grey>.vc_grid-filter-item:hover{
      background-color: transparent;
    }

  }
  .vc_gitem-post-data-source-post_date{
    font-weight: bold;
  }
  .vc_gitem-row-position-top{
    .vc_btn3{
      display: inline-block;
      background-color: $primary !important;
      border-radius: $border-radius;
      padding: 4px 10px 4px 10px;
      align-self: start;
      font-weight: bold;
      font-size: 18*$px-base;
      color: $secondary;

      &:hover{
        text-decoration: none !important;
      }
    }
  }
}

.vc_grid-container .owl-carousel .owl-stage-outer{
  height: inherit !important;
  overflow: visible;
}

html body .vc_grid-pagination .vc_grid-pagination-list.vc_grid-pagination-color-grey>li>a, html body .vc_grid-pagination .vc_grid-pagination-list.vc_grid-pagination-color-grey>li>span,
html body .vc_grid-pagination .vc_grid-pagination-list.vc_grid-pagination-color-grey > li > a, html body .vc_grid-pagination .vc_grid-pagination-list.vc_grid-pagination-color-grey > li > span{
  background-color: $primary !important;
  font-size: 16*$px-base;
  padding: 5px;
}

.katbuttonbox{
  padding: 0px 25px 25px 25px;
  border-radius: 5px;

  h4{
    color: $secondary;
    font-size: 17*$px-base;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }

  div{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .button{
    display: inline-block;
    border-radius: 0px;
    padding: 5px 10px 5px 10px;
    margin: 6px 12px;
    align-self: start;
    font-weight: bold;
    color: $secondary;
    border: solid 3px $secondary;
    text-decoration: none;

    &:hover{
      background-color: $secondary;
      color: $primary;
    }
  }

  .collapsebutton{
    border-radius: 0px;
    display: block;
    text-align: center;
    width: 100%;
    color: $secondary;
    padding: 20px;
    border: none !important;
    text-decoration: none;
    font-weight: bold;
    .gimmemyarrow{
      stroke: $secondary !important;
      stroke-width: 6px;
      transform: rotate(0deg);
    }
    &:hover{
      background-color: #ffffff;
      color: $secondary;
      .gimmemyarrow{
        transform: rotate(180deg);
      }
    }
  }

  @include media-breakpoint-up(sm){
    .collapsebutton{
      display: none;
    }
    .collapse{
      display: flex !important;
    }
  }
}

html body .flexslider .slides img{
  height: auto;
}

//NEWSLETTERBALKEN
.newsletterbalken a:hover{
  text-decoration: none;
}
.newsletterbalken{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: rgba(0, 110, 185,0.6);
  padding: 10px;
  max-width: 775px;
  margin: auto;
  border-radius: 5px;
  margin-top: 55px;
  margin-bottom: 55px;
  div{
    margin: 5px;
  }

  .icon{
    svg{
      width: 30px;
      height: 30px;
      path{
        fill: $secondary;
      }
    }
  }
  .text{
    font-weight: bold;
  }
}

.printfriendly {
  float: none!important;
  text-align: right;
  img{
    margin-top: 15px;
   }
}
#tribe-events-footer{
  border-top: none !important;
}
.flexslider{
  -webkit-box-shadow: none !important;
  -o-box-shadow: none !important;
  box-shadow: none !important;
}

.newsletterbox{
  .btn{
    background-color: $primary !important;
    border-color: $primary !important;
    text-decoration: none !important;
  }

    input{
      display: block;
      border: none;
      background-color: $quaternary40;
    }
    input[type=submit] {
      display: inline;
      background-color: $primary !important;
      border-radius: 0px;
      padding: 4px 10px 4px 10px;
      align-self: start;
      font-weight: bold;
      font-size: 18*$px-base;
      color: $secondary !important;
      text-align: center;
      text-decoration: none;
      width: auto !important;
    }
    button{
      display: inline;
      background-color: $primary !important;
      border: none;
      border-radius: 0px;
      padding: 4px 10px 4px 10px;
      align-self: start;
      font-weight: bold;
      font-size: 18*$px-base;
      color: $secondary !important;
      text-align: center;
      text-decoration: none;
    }

}

.vc_grid-filter{
  li{
    background-color: transparent !important;
    span{
      display: inline-block !important;
      border-radius: 0px !important;
      padding: 5px 10px 5px 10px !important;
      margin: 6px 12px !important;
      align-self: start !important;
      font-weight: bold !important;
      color: #4B5055 !important;
      border: solid 3px #4B5055 !important;
      text-decoration: none !important;

    }
    &.vc_active{
      span{
        background-color: $primary;
      }
    }
  }
}

.vc_grid{
  .vc_grid-item{
    .wpb_single_image{
      margin-bottom: 0px !important;

      .isc-source-text span{
        left: 0px;
      }
    }
    .vc_gitem-post-data-source-post_categories{
      .vc_grid-filter-item{
        span{
          background-color: #F5E146;
          border-radius: 0px;
          color: #000000;
          display: inline-block !important;
          padding: 5px 10px;
          font-size: 0.75rem;
          min-height: 37px;
          display: inline-flex;
          align-items: end;
          font-weight: bold;
          text-decoration: none;
        }
      }
      .vc_btn3-container{
        margin-bottom: 20px;
      }
    }
  }
}
