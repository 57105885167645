body.contraststyle{
  background: #000000!important;
  background-color: #000000 !important;

  @import url(chrome://flashblock/content/flashblock.css);

  @media screen, projection, tv {

    &> div {
      outline: 1px solid #fff;
    }

    &div.a11y-toolbar {
      outline: none;
    }

    &div.a11y-toolbar .offscreen {
      outline: 1px solid #fff;
    }

    .slick-slide div{

    }

    a, abbr, acronym, address, article, aside, b, basefont, bdi, big,
    blink, blockquote, body, button, canvas, caption, center, cite, code,
    col, colgroup, command, dd, details, dfn, div:not(.tnteaser):not(.hasbackgroundimage), dir, dl, dt, em, embed,
    fieldset, figcaption, figure, font, footer, form, h1, h1 a, h1 a b, h1
    abbr, h1 center, h1 span, h2, h2 a, h2 a b, h2 abbr, h2 center, h2
    span, h3, h3 a, h3 a b, h3 abbr, h3 center, h3 span, h4, h4 a, h4 a b,
    h4 abbr, h4 center, h4 span, h5, h5 a, h5 a b, h5 abbr, h5 center, h5
    span, h6, h6 a, h6 a b, h6 abbr, h6 center, h6 span, header, hgroup,
    html, i, iframe, img, input, kbd, label, legend, li, listing, mark,
    marquee, menu, meter, multicol, nav, nobr, object, ol, option, p,
    plaintext, pre, progress, q, s, section, select, small, span, strike,
    strong, sub, summary, sup, table, td, textarea, th, time, tr, tt, u,
    ul, var, xmp {
      background-image: none !important;
      text-shadow: none !important;
    }

    a, abbr, acronym, address, article, aside, b, basefont, bdi, big,
    blink, blockquote, body, canvas, caption, center, cite, code, col,
    colgroup, command, dd, details, dfn, div:not(.tnteaser):not(.hasbackgroundimage), dir, dl, dt, em, embed,
    fieldset, figcaption, figure, font, footer, form, h1, h1 a, h1 a b, h1
    abbr, h1 center, h1 span, h2, h2 a, h2 a b, h2 abbr, h2 center, h2
    span, h3, h3 a, h3 a b, h3 abbr, h3 center, h3 span, h4, h4 a, h4 a b,
    h4 abbr, h4 center, h4 span, h5, h5 a, h5 a b, h5 abbr, h5 center, h5
    span, h6, h6 a, h6 a b, h6 abbr, h6 center, h6 span, header, hgroup,
    html, i, iframe, input, kbd, label, legend, li, listing, mark,
    marquee, menu, meter, multicol, nav, nobr, object, ol, option, p,
    plaintext, pre, progress, q, s, section, small, span, strike, strong,
    sub, summary, sup, table, td, textarea, th, time, tr, tt, u, ul, var,
    xmp {
      background: black !important;
      background-color: black !important;
    }

    .slick.kopfslider{
      .hasbackgroundimage::before {
        content: "";
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background: rgba(0,0,0,0.80)  !important;
        background-color: rgba(0,0,0,0.75)  !important;
        z-index: 50;
      }
      .hasbackgroundimage{
        .row, .row div{
          z-index: 100;
          background: none !important;
          background-color: transparent !important;
        }
      }
      .hasbackgroundimage{
        .row .contentbox div{
          background-color: #000000 !important;
        }
      }
    }

    .slimheader, .bottomnav{
      .logo img, img{
        background-color: #000000 !important;
      }
    }

    .slimheader .logo h1,
    .slimheader .logo a,
    .slimheaderpostpages .logo h1{
      line-height: 2rem;
    }

    .katbuttonbox{
      a{
        background-color: #000000 !important;
        border: solid 1px #ffffff;
        background: #600040 !important;
        background-color: #600040 !important;
      }
    }

    .kopfslider .slick-slide .isc-source-text,
    .slick3er .tnteaser .isc-source-text{
      z-index: 9999!important;
    }

    .postgridview .card, .youtube-iframe-placeholder{
      border: solid 1px #ffffff;

      button{
        border: solid 1px #ffffff !important;
        color: #00b0FF !important;
        text-decoration: underline !important;
      }
    }

    .slick3er{
      li{
        &>div{
          border: solid 1px #ffffff;
        }
        div.tnteaser::before {
            content: "";
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            background: rgba(0,0,0,0.80)  !important;
            background-color: rgba(0,0,0,0.75)  !important;
            z-index: 50;
        }
        .text{
          z-index: 100;
          background: none !important;
          background-color: #000000 !important;
        }

        div.tnteaser:hover{
          background: #400000 !important;
          background-color: #400000 !important;
        }
        div.tnteaser:hover{
          &::before{
            position: initial !important;
          }
        }
      }
    }

    .tnteaser, .card, .tothepostslink {
      .buttonbox .button, .btn{
        border: solid 1px #ffffff;
        background: #600040 !important;
        background-color: #600040 !important;
        padding-right: 20px !important;
        &::after{
          display: none;
        }
      }
    }

    .tnpostgridbox .vc_gitem-row-position-top .vc_btn3{
      border: solid 1px #ffffff;
      background: #600040 !important;
      background-color: #600040 !important;
      padding-right: 20px !important;
    }

    .kommentarbereich .kommentarboxbutton{
      border: solid 1px #ffffff;
      background: #600040 !important;
      background-color: #600040 !important;
      padding-right: 20px !important;
      color: #00b0FF !important;
    }

    .boxauthorcontainer{
      border: solid 1px #ffffff;
      width: 750px !important;
      margin: 0px;
    }

    .button span.iconpfeil{
      background: transparent !important;
      background-color: transparent !important;
    }

    .tothepostslink .btn{
      padding: 6px !important;
      font-weight: normal !important;
    }
    .tnbutton .button{
      border: solid 1px #ffffff;
      background: #600040 !important;
      background-color: #600040 !important;
    }

    a, abbr, article, aside, basefont, bdi, big, blink, blockquote, body,
    button, canvas, caption, center, code, col, colgroup, command, dd,
    details, dir, div, dl, dt, embed, fieldset, figcaption, figure, font,
    footer, form, header, hgroup, html, iframe, img, input, kbd, label,
    legend, li, listing, mark, marquee, menu, meter, multicol, nav, nobr,
    object, ol, option, p, plaintext, pre, progress, q, s, section,
    select, small, span, strike, sub, summary, sup, table, td, textarea,
    th, time, tr, tt, ul, var, xmp {
      color: #f3f3f3 !important;
    }

    acronym, b, strong {
      color: yellow !important;
    }

    address, cite, dfn, em, i, u {
      color: #FFFF80 !important;
    }

    h1, h1 a, h1 a b, h1 abbr, h1 center, h1 span, h2, h2 a, h2 a b, h2
    abbr, h2 center, h2 span, h3, h3 a, h3 a b, h3 abbr, h3 center, h3
    span, h4, h4 a, h4 a b, h4 abbr, h4 center, h4 span, h5, h5 a, h5 a b,
    h5 abbr, h5 center, h5 span, h6, h6 a, h6 a b, h6 abbr, h6 center, h6
    span {
      color: #50d0a0 !important;
    }

    img {
      background: #808080 !important;
      background-color: #808080 !important;
    }

    button {
      background: #600040 !important;
      background-color: #600040 !important;
    }

    select {
      -webkit-appearance: listbox !important;
      background: #500050 !important;
      background-color: #500050 !important;
    }

    a:link, a:link a, a:link abbr, a:link acronym, a:link address, a:link
    article, a:link aside, a:link b, a:link basefont, a:link bdi, a:link
    big, a:link blink, a:link blockquote, a:link body, a:link br, a:link
    button, a:link canvas, a:link caption, a:link center, a:link cite,
    a:link code, a:link col, a:link colgroup, a:link command, a:link dd,
    a:link details, a:link dfn, a:link dir, a:link div, a:link dl, a:link
    dt, a:link em, a:link embed, a:link fieldset, a:link figcaption,
    a:link figure, a:link font, a:link footer, a:link form, a:link h1,
    a:link h2, a:link h3, a:link h4, a:link h5, a:link h6, a:link header,
    a:link hgroup, a:link html, a:link i, a:link iframe, a:link img,
    a:link input, a:link kbd, a:link label, a:link legend, a:link li,
    a:link listing, a:link mark, a:link marquee, a:link menu, a:link
    meter, a:link multicol, a:link nav, a:link nobr, a:link object, a:link
    ol, a:link option, a:link p, a:link plaintext, a:link pre, a:link
    progress, a:link q, a:link s, a:link section, a:link select, a:link
    small, a:link span, a:link strike, a:link strong, a:link sub, a:link
    summary, a:link sup, a:link table, a:link td, a:link textarea, a:link
    th, a:link time, a:link tr, a:link tt, a:link u, a:link ul, a:link
    var, a:link xmp, a:link:active, a:link:active abbr, a:link:active
    acronym, a:link:active b, a:link:active basefont, a:link:active big,
    a:link:active br, a:link:active code, a:link:active div, a:link:active
    em, a:link:active font, a:link:active h1, a:link:active h2,
    a:link:active h3, a:link:active h4, a:link:active h5, a:link:active
    h6, a:link:active i, a:link:active kbd, a:link:active small,
    a:link:active span, a:link:active strong, a:link:active tt,
    a:link:active u, a:link:active var, a:visited, a:visited a, a:visited
    abbr, a:visited acronym, a:visited address, a:visited article,
    a:visited aside, a:visited b, a:visited basefont, a:visited bdi,
    a:visited big, a:visited blink, a:visited blockquote, a:visited body,
    a:visited br, a:visited button, a:visited canvas, a:visited caption,
    a:visited center, a:visited cite, a:visited code, a:visited col,
    a:visited colgroup, a:visited command, a:visited dd, a:visited
    details, a:visited dfn, a:visited dir, a:visited div, a:visited dl,
    a:visited dt, a:visited em, a:visited embed, a:visited fieldset,
    a:visited figcaption, a:visited figure, a:visited font, a:visited
    footer, a:visited form, a:visited h1, a:visited h2, a:visited h3,
    a:visited h4, a:visited h5, a:visited h6, a:visited header, a:visited
    hgroup, a:visited html, a:visited i, a:visited iframe, a:visited img,
    a:visited input, a:visited kbd, a:visited label, a:visited legend,
    a:visited li, a:visited listing, a:visited mark, a:visited marquee,
    a:visited menu, a:visited meter, a:visited multicol, a:visited nav,
    a:visited nobr, a:visited object, a:visited ol, a:visited option,
    a:visited p, a:visited plaintext, a:visited pre, a:visited progress,
    a:visited q, a:visited s, a:visited section, a:visited select,
    a:visited small, a:visited span, a:visited strike, a:visited strong,
    a:visited sub, a:visited summary, a:visited sup, a:visited table,
    a:visited td, a:visited textarea, a:visited th, a:visited time,
    a:visited tr, a:visited tt, a:visited u, a:visited ul, a:visited var,
    a:visited xmp, a:visited:active, a:visited:active abbr,
    a:visited:active acronym, a:visited:active b, a:visited:active
    basefont, a:visited:active big, a:visited:active br, a:visited:active
    code, a:visited:active div, a:visited:active em, a:visited:active
    font, a:visited:active h1, a:visited:active h2, a:visited:active h3,
    a:visited:active h4, a:visited:active h5, a:visited:active h6,
    a:visited:active i, a:visited:active kbd, a:visited:active small,
    a:visited:active span, a:visited:active strong, a:visited:active tt,
    a:visited:active u, a:visited:active var {
      cursor: pointer !important;
    }

    a:link, a:link abbr, a:link acronym, a:link b, a:link basefont,
    a:link big, a:link br, a:link code, a:link div, a:link em, a:link
    font, a:link h1, a:link h2, a:link h3, a:link h4, a:link h5, a:link
    h6, a:link i, a:link kbd, a:link small, a:link span, a:link strong,
    a:link tt, a:link u, a:link var {
      color: #00b0FF !important;
    }

    a:link, a:link abbr, a:link acronym, a:link b, a:link basefont,
    a:link big, a:link br, a:link code, a:link div, a:link em, a:link
    font, a:link h1, a:link h2, a:link h3, a:link h4, a:link h5, a:link
    h6, a:link i, a:link kbd, a:link small, a:link span, a:link strong,
    a:link tt, a:link u, a:link var, a:link:active, a:link:active abbr,
    a:link:active acronym, a:link:active b, a:link:active basefont,
    a:link:active big, a:link:active br, a:link:active code, a:link:active
    div, a:link:active em, a:link:active font, a:link:active h1,
    a:link:active h2, a:link:active h3, a:link:active h4, a:link:active
    h5, a:link:active h6, a:link:active i, a:link:active kbd,
    a:link:active small, a:link:active span, a:link:active strong,
    a:link:active tt, a:link:active u, a:link:active var, a:visited,
    a:visited abbr, a:visited acronym, a:visited b, a:visited basefont,
    a:visited big, a:visited br, a:visited code, a:visited div, a:visited
    em, a:visited font, a:visited h1, a:visited h2, a:visited h3,
    a:visited h4, a:visited h5, a:visited h6, a:visited i, a:visited kbd,
    a:visited small, a:visited span, a:visited strong, a:visited tt,
    a:visited u, a:visited var, a:visited:active, a:visited:active abbr,
    a:visited:active acronym, a:visited:active b, a:visited:active
    basefont, a:visited:active big, a:visited:active br, a:visited:active
    code, a:visited:active div, a:visited:active em, a:visited:active
    font, a:visited:active h1, a:visited:active h2, a:visited:active h3,
    a:visited:active h4, a:visited:active h5, a:visited:active h6,
    a:visited:active i, a:visited:active kbd, a:visited:active small,
    a:visited:active span, a:visited:active strong, a:visited:active tt,
    a:visited:active u, a:visited:active var {
      text-decoration: underline !important;
    }

    a:visited, a:visited abbr, a:visited acronym, a:visited b, a:visited
    basefont, a:visited big, a:visited br, a:visited code, a:visited div,
    a:visited em, a:visited font, a:visited h1, a:visited h2, a:visited
    h3, a:visited h4, a:visited h5, a:visited h6, a:visited i, a:visited
    kbd, a:visited small, a:visited span, a:visited strong, a:visited tt,
    a:visited u, a:visited var {
      color: #00FFFF !important;
    }

    ::-moz-selection, ::selection {
      background: #4080c0 !important;
      background-color: #4080c0 !important;
    }

    a[onclick], a[onclick] a, a[onclick] abbr, a[onclick] acronym,
    a[onclick] address, a[onclick] article, a[onclick] aside, a[onclick]
    b, a[onclick] basefont, a[onclick] bdi, a[onclick] big, a[onclick]
    blink, a[onclick] blockquote, a[onclick] body, a[onclick] br,
    a[onclick] button, a[onclick] canvas, a[onclick] caption, a[onclick]
    center, a[onclick] cite, a[onclick] code, a[onclick] col, a[onclick]
    colgroup, a[onclick] command, a[onclick] dd, a[onclick] details,
    a[onclick] dfn, a[onclick] dir, a[onclick] div, a[onclick] dl,
    a[onclick] dt, a[onclick] em, a[onclick] embed, a[onclick] fieldset,
    a[onclick] figcaption, a[onclick] figure, a[onclick] font, a[onclick]
    footer, a[onclick] form, a[onclick] h1, a[onclick] h2, a[onclick] h3,
    a[onclick] h4, a[onclick] h5, a[onclick] h6, a[onclick] header,
    a[onclick] hgroup, a[onclick] html, a[onclick] i, a[onclick] iframe,
    a[onclick] img, a[onclick] input, a[onclick] kbd, a[onclick] label,
    a[onclick] legend, a[onclick] li, a[onclick] listing, a[onclick] mark,
    a[onclick] marquee, a[onclick] menu, a[onclick] meter, a[onclick]
    multicol, a[onclick] nav, a[onclick] nobr, a[onclick] object,
    a[onclick] ol, a[onclick] option, a[onclick] p, a[onclick] plaintext,
    a[onclick] pre, a[onclick] progress, a[onclick] q, a[onclick] s,
    a[onclick] section, a[onclick] select, a[onclick] small, a[onclick]
    span, a[onclick] strike, a[onclick] strong, a[onclick] sub, a[onclick]
    summary, a[onclick] sup, a[onclick] table, a[onclick] td, a[onclick]
    textarea, a[onclick] th, a[onclick] time, a[onclick] tr, a[onclick]
    tt, a[onclick] u, a[onclick] ul, a[onclick] var, a[onclick] xmp,
    a[onclick]:active, a[onclick]:active abbr, a[onclick]:active acronym,
    a[onclick]:active b, a[onclick]:active basefont, a[onclick]:active
    big, a[onclick]:active br, a[onclick]:active code, a[onclick]:active
    div, a[onclick]:active em, a[onclick]:active font, a[onclick]:active
    h1, a[onclick]:active h2, a[onclick]:active h3, a[onclick]:active h4,
    a[onclick]:active h5, a[onclick]:active h6, a[onclick]:active i,
    a[onclick]:active kbd, a[onclick]:active small, a[onclick]:active
    span, a[onclick]:active strong, a[onclick]:active tt,
    a[onclick]:active u, a[onclick]:active var {
      cursor: pointer !important;
    }

    a[onclick], a[onclick] abbr, a[onclick] acronym, a[onclick] b,
    a[onclick] basefont, a[onclick] big, a[onclick] br, a[onclick] code,
    a[onclick] div, a[onclick] em, a[onclick] font, a[onclick] h1,
    a[onclick] h2, a[onclick] h3, a[onclick] h4, a[onclick] h5, a[onclick]
    h6, a[onclick] i, a[onclick] kbd, a[onclick] small, a[onclick] span,
    a[onclick] strong, a[onclick] tt, a[onclick] u, a[onclick] var {
      color: #00b0FF !important;
    }

    a[onclick], a[onclick] abbr, a[onclick] acronym, a[onclick] b,
    a[onclick] basefont, a[onclick] big, a[onclick] br, a[onclick] code,
    a[onclick] div, a[onclick] em, a[onclick] font, a[onclick] h1,
    a[onclick] h2, a[onclick] h3, a[onclick] h4, a[onclick] h5, a[onclick]
    h6, a[onclick] i, a[onclick] kbd, a[onclick] small, a[onclick] span,
    a[onclick] strong, a[onclick] tt, a[onclick] u, a[onclick] var,
    a[onclick]:active, a[onclick]:active abbr, a[onclick]:active acronym,
    a[onclick]:active b, a[onclick]:active basefont, a[onclick]:active
    big, a[onclick]:active br, a[onclick]:active code, a[onclick]:active
    div, a[onclick]:active em, a[onclick]:active font, a[onclick]:active
    h1, a[onclick]:active h2, a[onclick]:active h3, a[onclick]:active h4,
    a[onclick]:active h5, a[onclick]:active h6, a[onclick]:active i,
    a[onclick]:active kbd, a[onclick]:active small, a[onclick]:active
    span, a[onclick]:active strong, a[onclick]:active tt,
    a[onclick]:active u, a[onclick]:active var {
      text-decoration: underline !important;
    }

    a:link:hover, a:link:hover abbr, a:link:hover acronym, a:link:hover
    b, a:link:hover basefont, a:link:hover big, a:link:hover br,
    a:link:hover code, a:link:hover div, a:link:hover em, a:link:hover
    font, a:link:hover h1, a:link:hover h2, a:link:hover h3, a:link:hover
    h4, a:link:hover h5, a:link:hover h6, a:link:hover i, a:link:hover
    kbd, a:link:hover small, a:link:hover span, a:link:hover strong,
    a:link:hover tt, a:link:hover u, a:link:hover var, a:visited:hover,
    a:visited:hover abbr, a:visited:hover acronym, a:visited:hover b,
    a:visited:hover basefont, a:visited:hover big, a:visited:hover br,
    a:visited:hover code, a:visited:hover div, a:visited:hover em,
    a:visited:hover font, a:visited:hover h1, a:visited:hover h2,
    a:visited:hover h3, a:visited:hover h4, a:visited:hover h5,
    a:visited:hover h6, a:visited:hover i, a:visited:hover kbd,
    a:visited:hover small, a:visited:hover span, a:visited:hover strong,
    a:visited:hover tt, a:visited:hover u, a:visited:hover var {
      background: #400000 !important;
      background-color: #400000 !important;
    }

    a:link:active, a:link:active abbr, a:link:active acronym,
    a:link:active b, a:link:active basefont, a:link:active big,
    a:link:active br, a:link:active code, a:link:active div, a:link:active
    em, a:link:active font, a:link:active h1, a:link:active h2,
    a:link:active h3, a:link:active h4, a:link:active h5, a:link:active
    h6, a:link:active i, a:link:active kbd, a:link:active small,
    a:link:active span, a:link:active strong, a:link:active tt,
    a:link:active u, a:link:active var, a:visited:active,
    a:visited:active abbr, a:visited:active acronym, a:visited:active b,
    a:visited:active basefont, a:visited:active big, a:visited:active br,
    a:visited:active code, a:visited:active div, a:visited:active em,
    a:visited:active font, a:visited:active h1, a:visited:active h2,
    a:visited:active h3, a:visited:active h4, a:visited:active h5,
    a:visited:active h6, a:visited:active i, a:visited:active kbd,
    a:visited:active small, a:visited:active span, a:visited:active
    strong, a:visited:active tt, a:visited:active u, a:visited:active var {
      color: red !important;
    }

    button.disabled, input.disabled, select.disabled, textarea.disabled {
      background: #404040 !important;
      background-color: #404040 !important;
    }

    a[onclick]:hover, a[onclick]:hover abbr, a[onclick]:hover acronym,
    a[onclick]:hover b, a[onclick]:hover basefont, a[onclick]:hover big,
    a[onclick]:hover br, a[onclick]:hover code, a[onclick]:hover div,
    a[onclick]:hover em, a[onclick]:hover font, a[onclick]:hover h1,
    a[onclick]:hover h2, a[onclick]:hover h3, a[onclick]:hover h4,
    a[onclick]:hover h5, a[onclick]:hover h6, a[onclick]:hover i,
    a[onclick]:hover kbd, a[onclick]:hover small, a[onclick]:hover span,
    a[onclick]:hover strong, a[onclick]:hover tt, a[onclick]:hover u,
    a[onclick]:hover var {
      background: #400000 !important;
      background-color: #400000 !important;
    }

    input[type=reset] {
      background: #400060 !important;
      background-color: #400060 !important;
    }

    a[onclick]:active, a[onclick]:active abbr, a[onclick]:active acronym,
    a[onclick]:active b, a[onclick]:active basefont, a[onclick]:active
    big, a[onclick]:active br, a[onclick]:active code, a[onclick]:active
    div, a[onclick]:active em, a[onclick]:active font, a[onclick]:active
    h1, a[onclick]:active h2, a[onclick]:active h3, a[onclick]:active h4,
    a[onclick]:active h5, a[onclick]:active h6, a[onclick]:active i,
    a[onclick]:active kbd, a[onclick]:active small, a[onclick]:active
    span, a[onclick]:active strong, a[onclick]:active tt,
    a[onclick]:active u, a[onclick]:active var {
      color: red !important;
    }

    div[role="button"], input[type=button], input[type=submit] {
      background: #600040 !important;
      background-color: #600040 !important;
    }

    input[type=search] {
      -webkit-appearance: textfield !important;
    }

    :not(:empty) a:after, :not(:empty) a:before, :not(:empty) abbr:after,
    :not(:empty) abbr:before, :not(:empty) acronym:after, :not(:empty)
    acronym:before, :not(:empty) address:after, :not(:empty)
    address:before, :not(:empty) article:after, :not(:empty)
    article:before, :not(:empty) aside:after, :not(:empty) aside:before,
    :not(:empty) b:after, :not(:empty) b:before, :not(:empty)
    basefont:after, :not(:empty) basefont:before, :not(:empty) bdi:after,
    :not(:empty) bdi:before, :not(:empty) big:after, :not(:empty)
    big:before, :not(:empty) blink:after, :not(:empty) blink:before,
    :not(:empty) blockquote:after, :not(:empty) blockquote:before,
    :not(:empty) body:after, :not(:empty) body:before, :not(:empty)
    button:after, :not(:empty) button:before, :not(:empty) canvas:after,
    :not(:empty) canvas:before, :not(:empty) caption:after, :not(:empty)
    caption:before, :not(:empty) center:after, :not(:empty) center:before,
    :not(:empty) cite:after, :not(:empty) cite:before, :not(:empty)
    code:after, :not(:empty) code:before, :not(:empty) col:after,
    :not(:empty) col:before, :not(:empty) colgroup:after, :not(:empty)
    colgroup:before, :not(:empty) command:after, :not(:empty)
    command:before, :not(:empty) dd:after, :not(:empty) dd:before,
    :not(:empty) details:after, :not(:empty) details:before, :not(:empty)
    dfn:after, :not(:empty) dfn:before, :not(:empty) dir:after,
    :not(:empty) dir:before, :not(:empty) div:after, :not(:empty)
    div:before, :not(:empty) dl:after, :not(:empty) dl:before,
    :not(:empty) dt:after, :not(:empty) dt:before, :not(:empty) em:after,
    :not(:empty) em:before, :not(:empty) embed:after, :not(:empty)
    embed:before, :not(:empty) fieldset:after, :not(:empty)
    fieldset:before, :not(:empty) figcaption:after, :not(:empty)
    figcaption:before, :not(:empty) figure:after, :not(:empty)
    figure:before, :not(:empty) font:after, :not(:empty) font:before,
    :not(:empty) footer:after, :not(:empty) footer:before, :not(:empty)
    form:after, :not(:empty) form:before, :not(:empty) h1:after,
    :not(:empty) h1:before, :not(:empty) h2:after, :not(:empty) h2:before,
    :not(:empty) h3:after, :not(:empty) h3:before, :not(:empty) h4:after,
    :not(:empty) h4:before, :not(:empty) h5:after, :not(:empty) h5:before,
    :not(:empty) h6:after, :not(:empty) h6:before, :not(:empty)
    header:after, :not(:empty) header:before, :not(:empty) hgroup:after,
    :not(:empty) hgroup:before, :not(:empty) html:after, :not(:empty)
    html:before, :not(:empty) i:after, :not(:empty) i:before, :not(:empty)
    iframe:after, :not(:empty) iframe:before, :not(:empty) img:after,
    :not(:empty) img:before, :not(:empty) input:after, :not(:empty)
    input:before, :not(:empty) kbd:after, :not(:empty) kbd:before,
    :not(:empty) label:after, :not(:empty) label:before, :not(:empty)
    legend:after, :not(:empty) legend:before, :not(:empty) li:after,
    :not(:empty) li:before, :not(:empty) listing:after, :not(:empty)
    listing:before, :not(:empty) mark:after, :not(:empty) mark:before,
    :not(:empty) marquee:after, :not(:empty) marquee:before, :not(:empty)
    menu:after, :not(:empty) menu:before, :not(:empty) meter:after,
    :not(:empty) meter:before, :not(:empty) multicol:after, :not(:empty)
    multicol:before, :not(:empty) nav:after, :not(:empty) nav:before,
    :not(:empty) nobr:after, :not(:empty) nobr:before, :not(:empty)
    object:after, :not(:empty) object:before, :not(:empty) ol:after,
    :not(:empty) ol:before, :not(:empty) option:after, :not(:empty)
    option:before, :not(:empty) p:after, :not(:empty) p:before,
    :not(:empty) plaintext:after, :not(:empty) plaintext:before,
    :not(:empty) pre:after, :not(:empty) pre:before, :not(:empty)
    progress:after, :not(:empty) progress:before, :not(:empty) q:after,
    :not(:empty) q:before, :not(:empty) s:after, :not(:empty) s:before,
    :not(:empty) section:after, :not(:empty) section:before, :not(:empty)
    select:after, :not(:empty) select:before, :not(:empty) small:after,
    :not(:empty) small:before, :not(:empty) span:after, :not(:empty)
    span:before, :not(:empty) strike:after, :not(:empty) strike:before,
    :not(:empty) strong:after, :not(:empty) strong:before, :not(:empty)
    sub:after, :not(:empty) sub:before, :not(:empty) summary:after,
    :not(:empty) summary:before, :not(:empty) sup:after, :not(:empty)
    sup:before, :not(:empty) table:after, :not(:empty) table:before,
    :not(:empty) td:after, :not(:empty) td:before, :not(:empty)
    textarea:after, :not(:empty) textarea:before, :not(:empty) th:after,
    :not(:empty) th:before, :not(:empty) time:after, :not(:empty)
    time:before, :not(:empty) tr:after, :not(:empty) tr:before,
    :not(:empty) tt:after, :not(:empty) tt:before, :not(:empty) u:after,
    :not(:empty) u:before, :not(:empty) ul:after, :not(:empty) ul:before,
    :not(:empty) var:after, :not(:empty) var:before, :not(:empty)
    xmp:after, :not(:empty) xmp:before, :root:not(HTML), :root:not(HTML)
    :not(:empty), .wp-block-group, .has-text-color {
      background: black !important;
      background-color: black !important;
      background-image: none !important;
      color: #f3f3f3 !important;
      text-shadow: none !important;
    }

    body.mediawiki img.tex {
      background: white !important;
      background-color: white !important;
    }

    button[disabled="disabled"], input[disabled="disabled"],
    select[disabled="disabled"], textarea[disabled="disabled"] {
      background: #404040 !important;
      background-color: #404040 !important;
    }

    :root:not(HTML) slider:not(:empty) {
      background: #301090 !important;
      background-color: #301090 !important;
    }

    .diffchange, .was, .now, .vc_diff_change, .vc_diff_remove, .vc_diff_add, .wDiffHtmlDelete, .wDiffHtmlInsert {
      color: #FFFF80 !important;
    }

    .wDiffHtmlDelete {
      text-decoration: line-through !important;
    }

    a.new {
      color: #FFFF40 !important;
    }

    body.mediawiki > div.navpopup, body.mediawiki .referencetooltip, ul.ui-autocomplete {
      position: absolute !important;
      border: blue solid !important;
    }

    font[color] {
      color: #FFFF40 !important;
    }

    span.Apple-style-span {
      color: #FFFF40 !important;
    }

    tr.new td {
      border: thick solid #FFFF40 !important;
    }

    ul.profile-icons li span {
      display: inline !important;
    }

    div.tooltip.dropShadow20 {
      border: thin solid #FFFF80 !important;
    }

    :not(:empty) li#menuNavigation.iconOnly > a > span.icon:after {
      content: "Navigation";
      text-transform: none;
    }

    :not(:empty) li#menuSearchHitNext.iconOnly > a > span.icon:after {
      content: "Next hit";
      text-transform: none !important;
    }

    :not(:empty) li#menuToolsPreferences.iconOnly > a > span.icon:after {
      content: "Preferences";
      text-transform: none;
    }

    :not(:empty) div.resultNavControls > ul > li.resultNavLeft > a > span:after {
      content: "<- Prev";
      text-transform: none;
    }

    :not(:empty) div.resultNavControls > ul > li.resultNavRight > a > span:after {
      content: "Next ->";
      text-transform: none;
    }

    :not(:empty) div.resultNavControls > ul > li.resultNavDoubleLeft > a > span:after {
      content: "<<- Backwd";
      text-transform: none;
    }

    :not(:empty) div.resultNavControls > ul > li.resultNavDoubleRight > a > span:after {
      content: "Fwd ->>";
      text-transform: none;
    }

    body > input#rsconf + div#wrapper > div#header + div#container > div#spacer + div#content span.hl, body > input#rsconf + div#wrapper > div#header + div#container > div#spacer + div#secondaryNav + div#content span.hl {
      background: #003050 !important;
    }

    div.result > div.document span.mk, div.result > div.document span.mk b, div.par p.sb span.mk, div.par p.ss span.mk b {
      background: #400060 !important;
    }

    .menu li a span.label {
      display: inline !important;
      text-transform: none !important;
    }

    body.yesJS > div.fancybox-wrap[style] {
      position: absolute !important;
      border: blue solid !important;
    }

    body.yesJS > div.fancybox-wrap[style] div.fancybox-close:after {
      content: "Close";
    }

    div.youtube5top-overlay, div.youtube5bottom-overlay, div.youtube5info, div.youtube5info-button, div.youtube5controls {
      background-color: transparent !important;
      background: transparent !important;
    }

    input[type=text], input[type=password], input[type=search] {
      border: 1px solid grey !important;
    }

    * {
      -webkit-box-shadow: none !important;
    }

    :focus {
      outline: thin dotted;
    }
  }

  /* end of @media block */

  ::-webkit-input-placeholder {
    color: #fff;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    color: #fff;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    color: #fff;
  }

  :-ms-input-placeholder {
    color: #fff;
  }

  form{
    input[type=text], input[type=email], input[type=submit], textarea{
      border: solid 2px #ffffff !important;
    }
    .was-validated .form-control:invalid, .form-control.is-invalid, .was-validated .custom-select:invalid, .custom-select.is-invalid{
      border-color: #dc3545 !important;
    }

    .text-danger *{
      color: #dc3545 !important;
    }
  }

  //Netzwerkspecials
  .best_practice_kategorie-legende{
    .katitem {
      border: solid 1px #ffffff;
      padding: 10px !important;
      margin: 10px;
      img{
        display: none;
      }
    }
  }
  .card .best_practice_kategorie{
    display: none;
  }

  #tribe-events {
    .tribe-bar-views-toggle span{
      display: none !important;
    }

    #tribe-bar-views-toggle:after{
      background-color: transparent !important;
    }
  }

  .searchpagebox {
    .solrsortbar #solr-sort{
      background: #600040 !important;
      background-color: #600040 !important;
    }
    .facet button {
      span {
        background: transparent !important;
        background-color: transparent !important;
      }

      &::after{
        background-color: transparent !important;
        background: transparent !important;
      }
    }
  }
  .newsletterstartbox{
    a{
      border: solid 1px #ffffff;
      background: #600040 !important;
      background-color: #600040 !important;
      padding: 10px !important;
    }
  }

  .tnpostgridbox .vc_gitem-row-position-top .vc_btn3:after{
    display: none;
  }

  .tnpostgridbox .vc_gitem-row-position-top .vc_gitem-col,
  .postgridview .type-beteiligungsangebote{
    border: solid 1px white;
  }

  &.wpb-js-composer .vc_tta.vc_general.vc_tta-color-orange .vc_tta-panel-heading{
    *{
      color: white !important;
    }
    .vc_tta-controls-icon{
      &::before, &::after{
        border-color: #ffffff !important;
      }
    }
  }
  &.wpb-js-composer .vc_tta.vc_general.vc_tta-color-orange {
    *{
      color: white !important;
    }
  }

  .ubermenu .ubermenu-target-text{
    padding-left: 15px;
  }

  .slimheader .logo img{
    -webkit-filter: grayscale(1) invert(1);
    filter: grayscale(1) invert(1);
  }

  .logospinne{
    .cls-1{
      fill: #ffffff;
    }
  }

  .tnpostgridbox .vc_gitem-row-position-top .vc_custom_heading h5,
  .vc_gitem-post-data-source-post_excerp {
    word-break: break-word;
  }

  nav{
    border: none !important;
  }

  .tribe-common .tribe-common-c-svgicon__svg-fill{
    fill: #ffffff;
  }

  .postgridview .card-text .text-muted{
    color: #ffffff !important;
  }

  .slimheader.onmobile2 .logo svg,
  .footerlogo svg,
  .slimheader.ondesktop2 #logo svg{
    fill: #ffffff;
  }

  .tribe-events-calendar-latest-past__event-details *{
    color: #ffffff !important;
  }

  .vc_grid-filter>.vc_grid-filter-item>span{
    border-color: #ffffff !important;
  }
}

