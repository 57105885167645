.bottomnav {
  padding: 15px;
  margin-bottom: 10px;

  @include media-breakpoint-down(xs) {
    padding: 0px;
  }
  a{
    display: block;
    line-height: 2rem;
    font-weight: bold;
    color: $secondary;
    @include media-breakpoint-up(xs) {
      display: block;
    }
  }
}

.footer{
  background-color: $primary;
  min-height: 183px;
  overflow: hidden;
  overflow-y: hidden !important;
  .container{
    min-height: 183px;
    div{
      margin-top: 10px;
      margin-bottom: 30px;
    }
    .col-12{
      a, span{
        padding: 0px 5px;
      }
    }
  }

  .footercooplogos{
    img{
      margin-bottom: 20px;
    }
  }

  @include media-breakpoint-down(md) {
    .bottomnav{
      margin-top: 0px !important;
      text-align: center;
    }
    .menu-footermenue-container{
      margin-top: 0px !important;
      ul{
        padding-left: 0px !important;
      }
    }
    .footerlogomobile{
      margin-top: 20px !important;
    }
  }

  .socialicons{
    display: flex;
    margin-bottom: 15px;
    justify-content: space-between;
    @include media-breakpoint-down(md) {
      justify-content: center;
    }
    img{
      width: 50px;
      margin: 0px 5px;
    }
  }
}

.footerlogo{
  display: none;
  @include media-breakpoint-up(md) {
    display: block;
    text-align: center;
  }
  svg{
    fill: #4D5256;
    padding-top: 15px;
    height: 70px;
  }

}

.footerlogomobile{
  display: block;
  margin: 85px 0px 35px 0px !important;
  text-align: center;
  @include media-breakpoint-up(md) {
    display: none;
  }
}

#menu-footer{
  list-style: none;
}

.menu-footermenue-container{
  margin-top: 0px !important;
}

@include media-breakpoint-up(lg) {
  .footercooplogos {
    display: flex;
    justify-content: space-between;
  }
}