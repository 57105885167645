@media print {
  div.backstretch{
    display: none;
  }

  .slimheader, .hauptnavigation{
    display: none !important;
  }

  body.single-post {
    .weitereartikel, .headlinebox2{
      display: none !important;
    }
  }

  .footerlogos{
    display: none !important;
  }

  html body .headerlasche, html body .header {
    display: none !important;
  }

  html body{
    background-color: #ffffff !important;
    max-width: 18cm;
  }

  html body .container {
    width: 100% !important;
  }

  a[href]:after {
    content: none !important;
  }

  .navicol{
    display: none;
  }

  .innercontentborder{
    width: 100%;
    border-left: none !important;
  }

  .innerpostcontent{
    border-right: none !important;
  }

  .bigpostcontent{
    width: 100% !important;
  }

  .comment-respond{
    display: none !important;
  }
}