/* -----------------------------------------
   NEXT FORMS
----------------------------------------- */

#nex-forms .row{
  margin: 0 !important;
}

input[type=checkbox]{
  width: 50px !important;
}

html body .label-right .checkbox-wrap .nf-field-element {
  width: 50px;
}

.nf-form-wrap{
  .nf-form-content .list-select-wrap .nf-field-element>div,
  .nf-form-content input:not([type=button]),
  .nf-form-content textarea{
    border: none;
    background-color: $quaternary40;
  }

  .nf-form-content .list-select-wrap .nf-field-element>div,
  .nf-form-content input:not([type=button]){
    width: 60%;
  }

  .nf-form-content button,
  .nf-form-content input[type=button]{
    display: inline;
    background-color: $quaternary40;
    border-radius: 0px;
    padding: 4px 10px 4px 10px;
    align-self: start;
    font-weight: bold;
    font-size: 18*$px-base;
    color: $secondary;
    text-align: center;
    text-decoration: none;
  }
  .nf-form-content input[type=submit] {
    display: inline;
    background-color: $primary !important;
    border-radius: 0px;
    padding: 4px 10px 4px 10px;
    align-self: start;
    font-weight: bold;
    font-size: 18*$px-base;
    color: $secondary !important;
    text-align: center;
    text-decoration: none;
    width: auto !important;
  }
}

.nf-form-cont{
  background-color: #ffffff;
}

.nf-form-content{
  padding: 0px !important;
  max-width: inherit !important;
}

.nf-form-fields-required{
  display: none;
}

.has-error{
  color: red !important;
}