
.hintergrundbild{
  *{
    color: #ffffff !important;
  }
  .vc_btn3{
    color: $tertiary !important;
  }
}

.svg200 img{
  width: 200px;
}

.newsletterbox{
  ul{
    list-style: none;

    li{
      display: inline-flex;
      align-items: end;
    }
    li:before{
        content:'';
        display:inline-block;
        height:36px;
        width: 45px;
        background-image:url("images/icons/haken.svg");
        background-size:45px;
        background-repeat:no-repeat;
        padding-left: 2em;
        margin-left: -39px;
    }
  }
}

//ACCODION STYLING
.wpb-js-composer .vc_tta.vc_general .vc_tta-panel.vc_active .vc_tta-panel-body{
 background-color: #ffffff;
}
.wpb-js-composer .vc_tta.vc_general {
  .vc_tta-panel-heading{
    border-radius: 0px !important;
    .vc_tta-title-text{
      font-size: 16*$px-base;
      font-weight: bold;
    }
  }
}
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-controls-icon-position-left.vc_tta-panel-title>a{
  padding-left: 55px !important;
}
.wpb-js-composer .vc_tta-color-white.vc_tta-style-flat .vc_tta-panel .vc_tta-panel-heading{
  background-color: #ffffff !important;
}
.wpb-js-composer .vc_tta-color-grey.vc_tta-style-classic .vc_tta-controls-icon::after, .wpb-js-composer .vc_tta-color-grey.vc_tta-style-classic .vc_tta-controls-icon::before{
  border-color: $primary !important;
}
.wpb-js-composer .vc_tta-color-white.vc_tta-style-flat .vc_active .vc_tta-panel-heading .vc_tta-controls-icon::after, .wpb-js-composer .vc_tta-color-white.vc_tta-style-flat .vc_active .vc_tta-panel-heading .vc_tta-controls-icon::before, .wpb-js-composer .vc_tta-color-white.vc_tta-style-flat .vc_tta-controls-icon::after, .wpb-js-composer .vc_tta-color-white.vc_tta-style-flat .vc_tta-controls-icon::before{
  border-color: $quaternary !important;
}
html body.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-controls-icon-position-left .vc_tta-controls-icon{
  width: 20px !important;
  height: 20px !important;
}

//Ansprechpartner Teaser
.ansprechpartnerteaser{
  max-width: 532px;
  max-height: 566px;
  height: 566px;
  background-position: bottom;
  background-repeat: repeat-x;
  flex-direction: column;
  display: flex;
  justify-content: end;
  @include media-breakpoint-down(xs) {
    margin-bottom: 35px;
  }

  .vorschaubild{
    img{
      width: 100%;
      max-width: 340px;
    }
  }
  .info{
    padding: 30px;
    .abteilungen{
      color: $primary;
      font-weight: bold;
    }
    h3{
      font-weight: bold;
      margin-bottom: 0px;
    }
    .telefon, .email{
      a{
        color: #ffffff;
        text-decoration: none;
      }
    }
  }
  .ansprechpartnerfooter{
    padding-top: 20px;
    &>div{
      display: flex;
      padding-bottom: 7px;
    }
    .icon{
      margin-right: 10px;
    }
  }
}

.ankerbutton{
  border: solid 3px $secondary;
  padding: 5px 10px;
  margin: 5px;
  justify-content: center;
  text-decoration: none !important;
  font-weight: bold;
  display: inline-block !important;
}

.wpb-js-composer .vc_tta-color-blue.vc_tta-style-classic .vc_tta-panel .vc_tta-panel-heading{
  border-color: $primary !important;
  background-color: $primary !important;
  color: #ffffff !important;
  *{
    color: #ffffff !important;
  }
}
.wpb-js-composer .vc_tta-color-blue.vc_tta-style-classic .vc_active .vc_tta-panel-heading .vc_tta-controls-icon::after, .wpb-js-composer .vc_tta-color-blue.vc_tta-style-classic .vc_active .vc_tta-panel-heading .vc_tta-controls-icon::before{
  border-color: #ffffff !important;
}

.mobile-full-yellow-back{
  @include media-breakpoint-down(xs) {
    .contentbox{
      h3{
        margin: 30px 0px;
        color: $secondary;
      }
      .tnbutton a{
        background-color: $secondary;
        color: $primary;
      }

    }
    &> .wpb_column > .vc_column-inner{
      margin-left: 20px;
    }
  }
}