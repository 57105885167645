/* work-sans-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/work-sans-v18-latin-regular.eot'); /* IE9 Compat Modes */
  src: url('fonts/work-sans-v18-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('fonts/work-sans-v18-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('fonts/work-sans-v18-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('fonts/work-sans-v18-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('fonts/work-sans-v18-latin-regular.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: bold;
  src: url('fonts/work-sans-v18-latin-600.eot'); /* IE9 Compat Modes */
  src: url('fonts/work-sans-v18-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('fonts/work-sans-v18-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('fonts/work-sans-v18-latin-600.woff') format('woff'), /* Modern Browsers */
  url('fonts/work-sans-v18-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('fonts/work-sans-v18-latin-600.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-800 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Work Sans';
  font-style: bold;
  font-weight: 800;
  src: url('fonts/WorkSans-ExtraBold.eot'); /* IE9 Compat Modes */
  src: url('fonts/WorkSans-ExtraBold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('fonts/WorkSans-ExtraBold.woff2') format('woff2'), /* Super Modern Browsers */
  url('fonts/WorkSans-ExtraBold.woff') format('woff'), /* Modern Browsers */
  url('fonts/WorkSans-ExtraBold.ttf') format('truetype'), /* Safari, Android, iOS */
  url('fonts/WorkSans-ExtraBold.svg#WorkSans') format('svg'); /* Legacy iOS */
}